import { Help, Settings } from 'assets/icons';
import {
    Notification,
    NotificationActive,
    ShoppingCart,
} from 'assets/icons/material';
import Badge from 'components/Badge';
import { IconButton } from 'components/Buttons';
import { trackCartOpened, trackCreateNewShipment } from 'external/analytics';
import { selectorId, useBeamerContext } from 'external/beamer';
import { useFlags } from 'external/launchDarkly';
import { getHelpCenterLink } from 'helpers/LinkHelper';
import UserDataHelper from 'helpers/UserDataHelper';
import { useCartContext } from 'hooks/Cart';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, useHistory, useLocation } from 'react-router-dom';
import styled, { DefaultTheme, ThemeContext } from 'styled-components/macro';

import { useFavorites } from '../../hooks/Queries/useFavorites';
import { useSupportedIntegrations } from '../../hooks/useSupportedIntegrations';
import Dropdown from '../Dropdown/Dropdown';
import DEPRECATEDFlexContainer from '../Layout/DEPRECATEDFlexContainer';
import LanguageSwitch from '../Layout/LanguageSwitch/LanguageSwitch';
import DEPRECATEDSpacer, { SpaceSize } from '../Spacings/DEPRECATEDSpacer';
import Tooltip from '../Tooltip';
import NavigationItem from './NavigationItem';
import UserMenu from './UserMenu';

const DesktopMenuContainer = styled.div`
    width: 100%;
`;

interface StyledLinkProps extends LinkProps {
    smallerRightPadding?: boolean;
    theme: DefaultTheme;
}

const StyledLink = styled(Link)<StyledLinkProps>`
    padding: ${({ theme, smallerRightPadding }) =>
        smallerRightPadding
            ? `${theme.space.s4} ${theme.space.s1} ${theme.space.s4} ${theme.space.s5}`
            : `${theme.space.s4} ${theme.space.s5}`};
`;

const DesktopMenu = () => {
    const { t } = useTranslation();
    const { showNavigation } = UserDataHelper;
    const {
        importNewBadge,
        beamer: showBeamer,
        showAnalyticsNavigationOption,
        showAnalyticsBetaBadge,
    } = useFlags();
    const history = useHistory();
    const { setCartVisibility, cartState } = useCartContext();
    const { consignments, isVisible } = cartState;
    const beamer = useBeamerContext();
    const { notificationNumber } = beamer;

    const { colors } = useContext(ThemeContext);
    const systems = useSupportedIntegrations();
    const enabled = systems.some((s) => s.status !== 'DISABLED');

    const showImportNewBadge =
        importNewBadge && localStorage.getItem('hasSeenImport') === null;
    const location = useLocation();

    const { data: favorites } = useFavorites();
    const favoriteItems = favorites?.map((favorite) => {
        return {
            id: favorite.id.toString(),
            title: favorite.favoriteName,
            icon: 'favorite_star',
            toggleItem: () => {
                trackCreateNewShipment(
                    'dropdown',
                    favorites?.length || 0,
                    favorite.id
                );
                history.push('/newshipment', {
                    consignment: JSON.stringify(favorite.favorite),
                    favoriteId: favorite.id,
                });
            },
        };
    });
    favoriteItems?.push({
        icon: '',
        id: 'fromScratch',
        title: t('menu.fromScratch'),
        toggleItem: () => {
            trackCreateNewShipment('dropdown', favorites?.length || 0);
            createNewShipment();
        },
    });

    const handleShipmentTitleClick = () => {
        trackCreateNewShipment('navbar', favorites?.length || 0);
        createNewShipment();
    };

    const createNewShipment = () =>
        history.push('/newshipment', {
            consignment: '',
            favoriteId: undefined,
        });

    return (
        <DesktopMenuContainer data-cy="desktop-menu">
            <DEPRECATEDFlexContainer
                fullWidth
                direction="row"
                justifyContent="space-between"
            >
                <DEPRECATEDSpacer marginLeft={SpaceSize.LARGE}>
                    <DEPRECATEDFlexContainer direction="row" fullHeight>
                        <NavigationItem
                            state={
                                location.pathname === '/dashboard' ||
                                location.pathname === '/'
                                    ? 'active'
                                    : 'normal'
                            }
                        >
                            <StyledLink to="/dashboard">
                                {t('menu.dashboard')}
                            </StyledLink>
                        </NavigationItem>
                        <NavigationItem
                            state={
                                location.pathname === '/newshipment'
                                    ? 'active'
                                    : 'normal'
                            }
                        >
                            {favoriteItems && favoriteItems.length > 1 ? (
                                <Dropdown
                                    title={t('menu.newShipment')}
                                    toggleIcon="assets/icons/misc/icons/ionic-arrow-right.svg"
                                    list={favoriteItems}
                                    dtype="button"
                                    withBorder={false}
                                    hoverToOpen={true}
                                    onTitleClick={handleShipmentTitleClick}
                                />
                            ) : (
                                <StyledLink to="/newshipment">
                                    {t('menu.newShipment')}
                                </StyledLink>
                            )}
                        </NavigationItem>
                        {enabled && (
                            <NavigationItem
                                state={
                                    location.pathname === '/import'
                                        ? 'active'
                                        : 'normal'
                                }
                            >
                                <StyledLink to="/import">
                                    {t('menu.import')}
                                </StyledLink>
                                {showImportNewBadge && (
                                    <Badge textType="text" variant="brand">
                                        {t('menu.new')}
                                    </Badge>
                                )}
                            </NavigationItem>
                        )}
                        <NavigationItem
                            state={
                                location.pathname === '/orders'
                                    ? 'active'
                                    : 'normal'
                            }
                        >
                            <StyledLink to="/orders">
                                {t('menu.orders')}
                            </StyledLink>
                        </NavigationItem>
                        {showAnalyticsNavigationOption && (
                            <NavigationItem
                                state={
                                    location.pathname === '/analytics'
                                        ? 'active'
                                        : 'normal'
                                }
                            >
                                <StyledLink
                                    smallerRightPadding={showAnalyticsBetaBadge}
                                    to="/analytics"
                                >
                                    {t('menu.insights')}
                                </StyledLink>
                                {showAnalyticsBetaBadge && (
                                    <Badge textType="text" variant="brand">
                                        {t('menu.beta')}
                                    </Badge>
                                )}
                            </NavigationItem>
                        )}
                        {showNavigation && (
                            <NavigationItem
                                state={
                                    location.pathname === '/admin'
                                        ? 'active'
                                        : 'normal'
                                }
                            >
                                <StyledLink to="/admin">
                                    {t('menu.admin')}
                                </StyledLink>
                            </NavigationItem>
                        )}
                    </DEPRECATEDFlexContainer>
                </DEPRECATEDSpacer>
                <DEPRECATEDFlexContainer direction="row">
                    <NavigationItem state="normal">
                        <LanguageSwitch />
                    </NavigationItem>
                    {showBeamer && (
                        <NavigationItem state="normal">
                            <IconButton
                                aria-label={t('menu.notificationCenter')}
                                id={selectorId}
                                icon={
                                    notificationNumber > 0 ? (
                                        <NotificationActive />
                                    ) : (
                                        <Notification />
                                    )
                                }
                                badge={
                                    notificationNumber > 0
                                        ? notificationNumber
                                        : undefined
                                }
                                badgeColor={colors.red200}
                            />
                        </NavigationItem>
                    )}
                    <NavigationItem state="normal">
                        <IconButton
                            data-cy="cart-button-toggle"
                            icon={<ShoppingCart />}
                            badge={
                                consignments.length > 0
                                    ? consignments.length
                                    : undefined
                            }
                            onClick={() => {
                                trackCartOpened('navbar');
                                setCartVisibility(!isVisible);
                            }}
                        />
                    </NavigationItem>
                    <Tooltip
                        className="small text"
                        id="settings"
                        place="bottom"
                    >
                        {t('menu.settings')}
                    </Tooltip>
                    <NavigationItem
                        state={
                            location.pathname === '/settings'
                                ? 'active'
                                : 'normal'
                        }
                    >
                        <Link
                            to="/settings"
                            id="tooltip-activate-text"
                            data-tip
                            data-tooltip-id="settings"
                        >
                            <IconButton icon={<Settings />} />
                        </Link>
                    </NavigationItem>
                    <NavigationItem state="normal">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getHelpCenterLink()}
                        >
                            <IconButton icon={<Help />} />
                        </a>
                    </NavigationItem>
                    <NavigationItem state="normal">
                        <UserMenu />
                    </NavigationItem>
                </DEPRECATEDFlexContainer>
            </DEPRECATEDFlexContainer>
        </DesktopMenuContainer>
    );
};

export default DesktopMenu;
